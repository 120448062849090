import "./global.scss";
import "./App.scss";

import { useState } from "react";

import { SamplesPage } from "./page/SamplesPage";
import { PasswordPage } from "./page/PasswordPage";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
    {
        path: "/",
        Component: () => <SamplesPage />
    }
])

function App() {
  const [showContent, setShowContent] = useState(false);

  if (!showContent)
      return <PasswordPage onConfirm={() => setShowContent(true)}/>

  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
