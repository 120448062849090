import "./Item.scss";
import { useState } from "react";
import { Card, Select, Button } from "antd";
import axios from "axios";

export function Item({ sample }) {
  const [transferred, setTransferred] = useState(sample.transferred);

  const [leftHumanLabel, setLeftHumanLabel] = useState(sample.leftHumanLabel);
  const [rightHumanLabel, setRightHumanLabel] = useState(
    sample.rightHumanLabel
  );
  const [symmetryHumanLabel, setSymmetryHumanLabel] = useState(
    sample.symmetryHumanLabel
  );

  function update(data) {
    axios
      .put(`/api/sample/${sample.id}`, {
        userId: localStorage.getItem("userId"),
        ...data,
      })
      .then(() => console.log("Success"));
  }

  return (
    <Card>
      <div className="sample-item">
        <div className="sample-item__header">
          <div>
            <b>ID:</b> {sample.id}
          </div>
          <div>
            <b>Created On:</b> {sample.createdOn || "---"}
          </div>
        </div>
        <div className="sample-item__symmetry">
          <img
            className="with-background"
            src={`${process.env.REACT_APP_IMAGE_HOST}/api/sample-picture/${sample.rowKey}?suffix=full.jpg`}
            width={100}
            height={100}
            style={{ objectFit: "cover" }}
          />
          <div>
            <Select
              style={{ width: 150 }}
              value={symmetryHumanLabel || ""}
              options={[
                { value: "", label: "None" },
                { value: "pass", label: "Pass" },
                { value: "refer", label: "Refer" },
                { value: "error", label: "Error" },
                { value: "unprocessed", label: "Unprocessed" },
              ]}
              onChange={(val) => {
                setSymmetryHumanLabel(val);
                update({
                  leftHumanLabel,
                  rightHumanLabel,
                  symmetryHumanLabel: val,
                });
              }}
            />

            <div className="sample-item__symmetry__value">
              <img src="/img/settings.svg" height={12} />
              &nbsp;
              {sample.statusSymmetry || "---"}
            </div>
          </div>
        </div>
        <div className="sample-item__content">
          <div className="sample-item__form">
            <Select
              style={{ width: 150 }}
              value={leftHumanLabel || ""}
              options={[
                { value: "", label: "None" },
                { value: "pass", label: "Pass" },
                { value: "refer", label: "Refer" },
                { value: "error", label: "Error" },
                { value: "unprocessed", label: "Unprocessed" },
              ]}
              onChange={(val) => {
                setLeftHumanLabel(val);

                update({
                  leftHumanLabel: val,
                  rightHumanLabel,
                  symmetryHumanLabel,
                });
              }}
            />
            <div className="sample-item__ml-result">
              <img src="/img/settings.svg" height={12} />
              &nbsp;
              {sample.leftMLResult || "---"}
            </div>
            <div className="sample-item__confidence">
              <div>
                <img src="/img/vote-up.svg" height="12" />
                &nbsp;
                {(+sample.leftMLConfidenceNormal || 0).toFixed(3)}
              </div>
              &nbsp;
              <div>
                <img src="/img/vote-down.svg" height="12" />
                &nbsp;
                {(+sample.leftMLConfidenceAbnormal || 0).toFixed(3)}
              </div>
            </div>
          </div>
          <div>
            <div className="sample-item__titles">
              <div>Left Single detection</div>
              <div>Right Single detection</div>
            </div>
            <div className="sample-item__pictures">
              <img
                className="sample-item__picture with-background"
                src={`${process.env.REACT_APP_IMAGE_HOST}/api/sample-picture/${sample.rowKey}?suffix=left_detection.png`}
              />
              <img
                className="sample-item__picture with-background"
                src={`${process.env.REACT_APP_IMAGE_HOST}/api/sample-picture/${sample.rowKey}?suffix=right_detection.png`}
              />
            </div>
          </div>
          <div className="sample-item__form">
            <Select
              style={{ width: 150 }}
              value={rightHumanLabel || ""}
              options={[
                { value: "", label: "None" },
                { value: "pass", label: "Pass" },
                { value: "refer", label: "Refer" },
                { value: "error", label: "Error" },
                { value: "unprocessed", label: "Unprocessed" },
              ]}
              onChange={(val) => {
                setRightHumanLabel(val);

                update({
                  leftHumanLabel,
                  rightHumanLabel: val,
                  symmetryHumanLabel,
                });
              }}
            />

            <div className="sample-item__ml-result">
              <img src="/img/settings.svg" height={12} />
              &nbsp;
              {sample.rightMLResult || "---"}
            </div>
            <div className="sample-item__confidence">
              <div>
                <img src="/img/vote-up.svg" height="12" />
                &nbsp;
                {(+sample.rightMLConfidenceNormal || 0).toFixed(3)}
              </div>
              &nbsp;
              <div>
                <img src="/img/vote-down.svg" height="12" />
                &nbsp;
                {(+sample.rightMLConfidenceAbnormal || 0).toFixed(3)}
              </div>
            </div>
          </div>
        </div>
      </div>

      {process.env.REACT_APP_ENABLE_TRANSFER === "true" && !transferred && (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 24 }}
        >
          <Button
            onClick={async () => {
              try {
                await axios.post(`/api/transfer/${sample.id}`);
                setTransferred(true);
                alert("Transfered");
              } catch (e) {
                alert("Transferring error");
              }
            }}
          >
            Upload to Train dashboard
          </Button>
        </div>
      )}

      {process.env.REACT_APP_ENABLE_TRANSFER === "true" && transferred && (
        <p style={{ textAlign: "center", color: "green" }}>Transferred</p>
      )}
    </Card>
  );
}
