import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Spin, Modal, Table, Form, Input, Divider } from "antd";

export function UsersModal({ open, onSelectUser, onClose }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newUsername, setNewUsername] = useState("");
  const [formError, setFormError] = useState("");

  useEffect(() => {
    axios.get("/api/user").then((resp) => {
      setLoading(false);
      setUsers(resp.data);
    });
  }, []);

  return (
    <Modal
      open={open}
      onOk={onClose}
      footer={false}
      closable={false}
      title="Choose the user"
    >
      {loading && <Spin />}
      {!loading && (
        <Table
          pagination={false}
          columns={[
            {
              title: "ID",
              dataIndex: "id",
              key: "id",
            },
            {
              title: "Name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "Action",
              key: "operation",
              render: (user) => {
                return (
                  <Button
                    onClick={() => {
                      onSelectUser(user);
                    }}
                  >
                    Continue as {user.name}
                  </Button>
                );
              },
            },
          ]}
          dataSource={users}
        />
      )}

      <Divider />

      <Form layout="vertical">
        <Form.Item
          label="New Username"
          validateStatus={formError ? "error" : null}
          help={formError ? formError : null}
        >
          <Input
            value={newUsername}
            onChange={(e) => setNewUsername(e.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button
            onClick={async () => {
              if (!newUsername.trim().length) {
                setFormError("Please, type a username");
                return;
              }

              setLoading(true);
              setNewUsername("");

              await axios.post("/api/user", {
                name: newUsername,
              });

              const resp = await axios.get("/api/user");
              setLoading(false);
              setUsers(resp.data);
            }}
          >
            Add
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
