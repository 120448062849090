import "./PasswordPage.scss";

import { useState } from "react";
import { Typography, Button, Input, Checkbox, Form } from "antd";

import { UsersModal } from "../components/UsersModal";

const { Paragraph } = Typography;

const DEFAULT_PASSWORD = "jjghdHsndn34";

export function PasswordPage({ onConfirm }) {
  const [errorPrivacy, setErrorPrivacy] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);

  return (
    <div className="password-page">
      <div className="password-page__content">
        <Form
          layout="vertical"
          onFinish={(data) => {
            setErrorPassword(false);
            setErrorPrivacy(false);

            if (!data.remember) {
              setErrorPrivacy(true);
              return;
            }

            if (data.password === DEFAULT_PASSWORD) {
              setShowModal(true);
            } else {
              setErrorPassword(true);
            }
          }}
        >
          <Form.Item
            label="Password:"
            name="password"
            validateStatus={errorPassword ? "error" : null}
            help={errorPassword ? "Wrong password" : null}
          >
            <Input type="password" placeholder="" />
          </Form.Item>
          <Form.Item
            name="remember"
            valuePropName="checked"
            validateStatus={errorPrivacy ? "error" : null}
            help={errorPrivacy ? "Please, accept our Privacy" : null}
          >
            <Checkbox>
              By clicking Log In, you agree to our Privacy Policy.
            </Checkbox>
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit">Log In</Button>
          </Form.Item>
        </Form>

        <Paragraph style={{ fontStyle: "italic", fontSize: 12 }}>
          Photographs and information in this database are private and the
          solely the property of Health Access, LLC. By accessing this database
          the user agrees to maintain the privacy of this database. Photographs
          and elements of the database are not to be copied or disseminated in
          any way.
        </Paragraph>

        <UsersModal
          open={showModal}
          onUserAdded={() => {
            
          }}
          onSelectUser={(user) => {
            localStorage.setItem("userId", user.id);
            setShowModal(false);
            onConfirm();
          }}
          onClose={() => setShowModal(false)}
        />
      </div>
    </div>
  );
}
